import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../components/LoadingScreen";
import { UploadFile as UploadFileIcon } from "@mui/icons-material"; // Import an upload icon from Material-UI
import {
  Modal,
  Box,
  Typography,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Button,
  RadioGroup,
  Radio,
} from "@mui/material";

const Modal5 = ({ open, onClose }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [boards, setBoards] = useState([]);
  const [grades, setGrades] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [chapters, setChapters] = useState([]); // Added state for chapters
  const [board, setBoard] = useState("");
  const [grade, setGrade] = useState("");
  const [subject, setSubject] = useState("");
  const [selectionType, setSelectionType] = useState("chapter"); // "chapter" or "topic"
  const [selectedChapter, setSelectedChapter] = useState("");
  const [topicName, setTopicName] = useState("");
  const [numLectures, setNumLectures] = useState("1");
  const [durLectures, setDurLectures] = useState("");
  const [classStrength, setClassStrength] = useState("");
  const [language, setLanguage] = useState("English");
  const [quiz, setQuiz] = useState(false);
  const [assignment, setAssignment] = useState(false);
  const [file, setFile] = useState(null); // Add state for file

  // Reset the modal fields when it is closed
  useEffect(() => {
    if (!open) {
      resetModalFields();
    }
  }, [open]);

  const resetModalFields = () => {
    setBoard("");
    setGrade("");
    setSubject("");
    setSelectionType("chapter");
    setSelectedChapter("");
    setTopicName("");
    setNumLectures("1");
    setDurLectures("");
    setClassStrength("");
    setLanguage("English");
    setQuiz(false);
    setAssignment(false);
    setFile(null);
  };

  useEffect(() => {
    const fetchBoards = async () => {
      try {
        const response = await axios.get(
          "https://agent-utilities-api.leoqui.ai/api/pdf/Get-dropdown-values"
        );
        if (response.data.status) {
          setBoards(response.data.data.data || []);
        }
      } catch (error) {
        console.error("Error fetching boards:", error);
      }
    };

    fetchBoards();
  }, []);

  useEffect(() => {
    if (board) {
      const fetchGrades = async () => {
        try {
          const response = await axios.get(
            `https://agent-utilities-api.leoqui.ai/api/pdf/Get-dropdown-values?board=${board}`
          );
          if (response.data.status) {
            setGrades(response.data.data.data || []);
          }
        } catch (error) {
          console.error("Error fetching grades:", error);
        }
      };

      fetchGrades();
    }
  }, [board]);

  useEffect(() => {
    if (board && grade) {
      const fetchSubjects = async () => {
        try {
          const response = await axios.get(
            `https://agent-utilities-api.leoqui.ai/api/pdf/Get-dropdown-values?board=${board}&grade=${grade}`
          );
          if (response.data.status) {
            setSubjects(response.data.data.data || []);
          }
        } catch (error) {
          console.error("Error fetching subjects:", error);
        }
      };

      fetchSubjects();
    }
  }, [board, grade]);

  // Fetch chapters when board, grade, and subject are selected
  useEffect(() => {
    if (board && grade && subject) {
      const fetchChapters = async () => {
        try {
          const response = await axios.get(
            `https://agent-utilities-api.leoqui.ai/api/pdf/Get-dropdown-values?board=${board}&grade=${grade}&subject=${subject}`
          );
          if (response.data.status && response.data.data?.data) {
            // Map the chapter data to a more usable format
            const chaptersData = response.data.data.data.map(
              ([chapterNumber, chapterName]) => ({
                chapter_number: chapterNumber,
                chapter_name: chapterName,
              })
            );
            setChapters(chaptersData); // Update chapters state
          }
        } catch (error) {
          console.error("Error fetching chapters:", error);
        }
      };

      fetchChapters();
    }
  }, [board, grade, subject]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const apiUrl =
        selectionType === "chapter"
          ? `https://lpg-api.leoqui.ai/lessonplan/Lesson_Plan`
          : `https://lpg-api.leoqui.ai/lessonplan/Lesson_Plan_from_Topic`;

      // Create the URL with query parameters
      const urlWithParams = new URL(apiUrl);
      const params = new URLSearchParams();

      params.append("board", board);
      params.append("grade", grade);
      params.append("subject", subject);
      params.append("number_of_lecture", numLectures);
      params.append("duration_of_lecture", durLectures);
      params.append("class_strength", classStrength);
      params.append("language", language);
      params.append("quiz", quiz);
      params.append("assignment", assignment);
      params.append("structured_output", false);

      // For By Chapter
      if (selectionType === "chapter") {
        params.append("chapter_number", selectedChapter);
      } else if (selectionType === "topic") {
        params.append("topic", topicName);
      }

      // Append the query parameters to the URL
      urlWithParams.search = params.toString();

      // FormData for file if present
      const formData = new FormData();
      if (file) {
        formData.append("file", file, file.name);
      }

      // Send the request with the URL and FormData for file
      const response = await axios.post(urlWithParams.toString(), formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        timeout: 300000, 
      });

      if (
        response.data.status === "success" &&
        response.data.data?.[0]?.lesson_plan
      ) {
        navigate("/lesson-plan-generator-V2", {
          state: { lessonPlan: response.data.data[0].lesson_plan },
        });
      } else {
        alert("Lesson plan could not be generated. Please try again.");
      }
    } catch (error) {
      console.error(
        "Error submitting the form:",
        error.response?.data || error
      );
      alert("An error occurred while generating the lesson plan.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <LoadingScreen />}
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "650px",
            maxHeight: "80vh", // Set maximum height for the modal
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            overflowY: "auto", // Enable vertical scrolling if content overflows
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            Lesson Plan Generation
          </Typography>

          {/* Dropdowns for Board, Grade, and Subject */}
          <FormControl fullWidth margin="normal">
            <InputLabel>Select Board</InputLabel>
            <Select
              value={board}
              onChange={(e) => setBoard(e.target.value)}
              variant="filled"
            >
              <MenuItem value="">Select Board</MenuItem>
              {boards.map((boardOption, index) => (
                <MenuItem key={index} value={boardOption}>
                  {boardOption}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {board && (
            <FormControl fullWidth margin="normal">
              <InputLabel>Select Grade</InputLabel>
              <Select
                value={grade}
                onChange={(e) => setGrade(e.target.value)}
                variant="filled"
              >
                <MenuItem value="">Select Grade</MenuItem>
                {grades.map((gradeOption, index) => (
                  <MenuItem key={index} value={gradeOption}>
                    {gradeOption}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {board && grade && (
            <FormControl fullWidth margin="normal">
              <InputLabel>Select Subject</InputLabel>
              <Select
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                variant="filled"
              >
                <MenuItem value="">Select Subject</MenuItem>
                {subjects.map((subjectOption, index) => (
                  <MenuItem key={index} value={subjectOption}>
                    {subjectOption}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {/* Remaining Options */}
          {subject && (
            <>
              {/* Options for Chapter or Topic */}
              <Typography variant="subtitle1" gutterBottom>
                Choose Option
              </Typography>
              <RadioGroup
                value={selectionType}
                onChange={(e) => setSelectionType(e.target.value)}
                row
              >
                <FormControlLabel
                  value="chapter"
                  control={<Radio />}
                  label="By Chapter"
                />
                <FormControlLabel
                  value="topic"
                  control={<Radio />}
                  label="By Topic"
                />
              </RadioGroup>

              {/* Chapter Dropdown */}
              {selectionType === "chapter" && (
                <FormControl fullWidth margin="normal">
                  <InputLabel>Select Chapter</InputLabel>
                  <Select
                    value={selectedChapter}
                    onChange={(e) => setSelectedChapter(e.target.value)}
                    variant="filled"
                  >
                    <MenuItem value="">Select Chapter</MenuItem>
                    {chapters.map((chapter, index) => (
                      <MenuItem key={index} value={chapter.chapter_number}>
                        {chapter.chapter_number}: {chapter.chapter_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {/* Topic Input */}
              {selectionType === "topic" && (
                <>
                  <TextField
                    fullWidth
                    margin="normal"
                    label="Enter Topic"
                    value={topicName}
                    onChange={(e) => setTopicName(e.target.value)}
                  />
                  <Typography variant="subtitle1" gutterBottom>
                    Add Reference File (Optional)
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px dashed #ccc",
                      borderRadius: "8px",
                      padding: "16px",
                      justifyContent: "space-between",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "#f9f9f9", // Subtle hover effect
                      },
                    }}
                    onClick={() => document.getElementById("fileInput").click()} // Trigger file input on box click
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <UploadFileIcon
                        sx={{
                          fontSize: "32px",
                          color: "#714B90",
                          marginRight: "12px",
                        }}
                      />
                      <Typography variant="body1" color="textSecondary">
                        Browse files
                      </Typography>
                    </Box>
                    <Typography variant="body2" color="textSecondary">
                      (PDF only)
                    </Typography>
                  </Box>
                  <input
                    id="fileInput"
                    type="file"
                    accept="application/pdf"
                    onChange={(e) => setFile(e.target.files[0])}
                    style={{ display: "none" }} // Hide default file input
                  />
                  {file && (
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{ marginTop: "8px" }}
                    >
                      Selected File: {file.name}
                    </Typography>
                  )}
                </>
              )}

              {/* Additional Fields */}
              <TextField
                fullWidth
                margin="normal"
                label="Number of Lectures"
                value={numLectures}
                onChange={(e) => {
                  // Allow only positive integers
                  const value = e.target.value;
                  if (/^\d+$/.test(value) || value === "") {
                    setNumLectures(value);
                  }
                }}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Duration of Lectures"
                value={durLectures}
                onChange={(e) => {
                  // Allow only positive integers
                  const value = e.target.value;
                  if (/^\d+$/.test(value) || value === "") {
                    setDurLectures(value);
                  }
                }}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Class Strength"
                value={classStrength}
                onChange={(e) => {
                  // Allow only positive integers
                  const value = e.target.value;
                  if (/^\d+$/.test(value) || value === "") {
                    setClassStrength(value);
                  }
                }}
              />
              <FormControl fullWidth margin="normal">
                <InputLabel>Language</InputLabel>
                <Select
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                  variant="filled"
                >
                  <MenuItem value="English">English</MenuItem>
                  <MenuItem value="Hindi">Hindi</MenuItem>
                </Select>
              </FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={quiz}
                    onChange={(e) => setQuiz(e.target.checked)}
                  />
                }
                label="Quiz"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={assignment}
                    onChange={(e) => setAssignment(e.target.checked)}
                  />
                }
                label="Assignment"
              />
            </>
          )}

          {/* Submit Button */}
          <Button
            fullWidth
            variant="contained"
            sx={{
              backgroundColor: "#714B90",
              "&:hover": { backgroundColor: "#5a3b72" }, // Optional: Adjust hover color
            }}
            onClick={handleSubmit}
            disabled={
              !subject || (selectionType === "chapter" && !selectedChapter)
            }
          >
            Generate Lesson Plan
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default Modal5;
