import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Welcome from "./components/Welcome";
import Result from "./components/Result";
import theme from "./theme"; // Import the theme
import LoginPage from "./components/LoginPage";
import LessonPlanGenerator from "./components/LessonPlanGenerator";
import ExamGenerator from "./components/ExamGenerator";
import LessonPlanGeneratorV2 from "./components/LessonPlanGeneratorV2";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="/result" element={<Result />} />
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/lesson-plan-generator"
            element={<LessonPlanGenerator />}
          />
          <Route
            path="/lesson-plan-generator-V2"
            element={<LessonPlanGeneratorV2 />}
          />
          <Route path="/exam-generator" element={<ExamGenerator />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
