import React, { useState } from "react";
import Header from "../components/Header";
import Modal1 from "../components/Modal1"; // Import the new Modal1 component
import Modal3 from "../components/Modal3"; // Import the new Modal3 component
import Modal4 from "../components/Modal4";
import Modal5 from "../components/Modal5";
import Modal6 from "../components/Modal6";
import Cal1 from "../assets/Cal2.png";
import Cal2 from "../assets/Cal1.png";
import Cal5 from "../assets/V2.png";
import Cal4 from "../assets/Cal4.svg";
import mshiksha from "../assets/meri shiksha.png";
import Calx from "../assets/Calx.png";
import Cal6 from "../assets/Cal6.png";
const Welcome = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openModal3, setOpenModal3] = useState(false);
  const [openModal4, setOpenModal4] = useState(false);
  const [openModal5, setOpenModal5] = useState(false);
  const [openModal6, setOpenModal6] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleOpenModal3 = () => setOpenModal3(true);
  const handleCloseModal3 = () => setOpenModal3(false);

  const handleOpenModal4 = () => setOpenModal4(true);
  const handleCloseModal4 = () => setOpenModal4(false);

  const handleOpenModal5 = () => setOpenModal5(true);
  const handleCloseModal5 = () => setOpenModal5(false);

  const handleOpenModal6 = () => setOpenModal6(true);
  const handleCloseModal6 = () => setOpenModal6(false);

  return (
    <div style={styles.container}>
      <Header />
      <div style={styles.imageWrapper}>
        <img src={mshiksha} alt="Meri Shiksha" style={styles.mshikshaImage} />
      </div>
      <div style={styles.content}>
        <h1>Welcome back Sharad</h1>
        <p style={{ wordBreak: "break-word" }}>
          Get started with the following features and take a step
          <br /> towards transforming education in India.
        </p>

        <div style={styles.iconContainer}>
          <div style={styles.iconItem} onClick={handleOpenModal}>
            <img src={Cal4} alt="oos grading" style={styles.icon} />
          </div>
          <div style={styles.iconItem} onClick={handleOpenModal3}>
            <img src={Cal1} alt="lesson-gen" style={styles.icon} />
          </div>
          <div style={styles.iconItem} onClick={handleOpenModal5}>
            <img src={Cal5} alt="classes" style={styles.icon} />
          </div>
          <div style={styles.iconItem} onClick={handleOpenModal4}>
            <img src={Cal2} alt="classes" style={styles.icon} />
          </div>
          <div style={styles.iconItem}>
            <a href="https://sandbox.megh.mashmari.in">
              <img src={Calx} alt="attendance" style={styles.icon} />
            </a>
          </div>
          <div style={styles.iconItem} onClick={handleOpenModal6}>
            <img src={Cal6} alt="ExamV2" style={styles.icon} />
          </div>
        </div>
      </div>
      <Modal1 open={openModal} onClose={handleCloseModal} />{" "}
      {/* Use the new Modal1 component */}
      <Modal3 open={openModal3} onClose={handleCloseModal3} />{" "}
      {/* Use the new Modal3 component */}
      <Modal4 open={openModal4} onClose={handleCloseModal4} />
      {""}
      {/* Use the new Modal4 component */}
      <Modal5 open={openModal5} onClose={handleCloseModal5} />
      {/* Use the new Modal5 component */}
      <Modal6 open={openModal6} onClose={handleCloseModal6} />{""}
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: "#FEFBF4",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    padding: "0 20px",
    alignItems: "center",
  },
  imageWrapper: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: "60px", // Ensure there's space between header and image
  },
  mshikshaImage: {
    maxWidth: "100%",
    height: "auto",
    margin: "0 auto",
  },
  content: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    fontFamily: "Montserrat, Helvetica, sans-serif",
    marginTop: "15px", // Increase margin for spacing
    paddingBottom: "20px", // Ensure space at the bottom
  },
  iconContainer: {
    display: "grid", // Use a grid layout for better control
    gridTemplateColumns: "repeat(3, 1fr)", // 3 columns for 3 icons per row
    gap: "30px", // Space between the icons
    marginTop: 20,
    width: "100%",
    maxWidth: "1200px",
    justifyItems: "center", // Center items horizontally
  },
  iconItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Soft shadow for 3D effect
    borderBottom: "1px solid #e0e0e0", // Subtle border to enhance the 3D effect
    borderRadius: "10px",
    width: "210px", // Set width for larger screens
    maxWidth: "100%", // Allow it to scale down
    height: "210px", // Set height for larger screens
  },
  icon: {
    width: "100%",
    height: "100%",
    marginBottom: 10,
    borderRadius: "12px",
  },
  // Add media query for mobile view
  "@media (max-width: 600px)": {
    content: {
      marginTop: "0", // Reset the margin on small screens
      paddingTop: "20px", // Add padding to prevent overlap
    },
    mshikshaImage: {
      width: "100%", // Scale down the image on mobile
      marginTop: "10px", // Add some space above the image
    },
    iconContainer: {
      gridTemplateColumns: "repeat(2, 1fr)", // 2 columns for small screens
      gap: "20px", // Reduce gap between icons
    },
    iconItem: {
      width: "150px", // Scale down the icon size
      height: "150px",
    },
  },
};


export default Welcome;
