import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Typography, Button, Divider } from "@mui/material";
import Header from "../components/Header";
import jsPDF from "jspdf";
import Logo from "../assets/Logo.png";
import "jspdf-autotable";

const TestPlanGeneratorV2 = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { TestPlanGeneratorV2: apiResponse } = location.state || {};
  const testData = apiResponse?.data;

  // Add Montserrat font
  jsPDF.API.events.push([
    "addFonts",
    function () {
      this.addFileToVFS(
        "Montserrat-Regular.ttf" /* Base64-encoded Montserrat font */
      );
      this.addFont("Montserrat-Regular.ttf", "Montserrat", "normal");
    },
  ]);

  const formatMCQOptions = (question) => {
    // Split the question at "Options:"
    const [questionText, optionsText] = question.split("Options:");
    
    // If there's no "Options:" text, return the original question
    if (!optionsText) return { questionText: question, options: [] };
    
    // Split options by looking for A), B), C), D)
    const options = optionsText.match(/[A-D]\)(.*?)(?=[A-D]\)|$)/g) || [];
    
    return {
      questionText: questionText.trim(),
      options: options.map(opt => opt.trim())
    };
  };
  
  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    doc.setFont("Montserrat", "normal");
    doc.setFontSize(14);

    // Add Logo
    const imgWidth = 50;
    const imgHeight = 20;
    doc.addImage(Logo, "PNG", 10, 10, imgWidth, imgHeight);

    let y = 40;

    doc.text("Generated Exam Paper", 10, y);
    y += 10;

    doc.setFontSize(12);

    if (testData) {
      // General Instructions
      if (testData.General_Instructions) {
        doc.text("General Instructions:", 10, y);
        y += 7;
        testData.General_Instructions.forEach((instruction, index) => {
          doc.text(`${index + 1}. ${instruction}`, 10, y);
          y += 7;
        });
        y += 10;
      }

      // Special handling for MCQs
      if (testData.MCQs) {
        doc.text("Multiple Choice Questions", 10, y);
        y += 7;

        testData.MCQs.forEach((questions) => {
          Object.entries(questions[0]).forEach(([questionNo, question]) => {
            const { questionText, options } = formatMCQOptions(question);
            
            doc.text(`${questionNo}. ${questionText}`, 10, y);
            y += 7;

            options.forEach((option) => {
              doc.text(option, 20, y);
              y += 7;
            });

            y += 5; // Add extra space between questions

            if (y > 280) {
              doc.addPage();
              y = 10;
            }
          });
        });
      }

      // Render other sections
      const sections = [
        "True_False_Questions",
        "Fill_in_the_Blanks",
        "Very_Short_Answers",
        "Short_Answers",
        "Long_Answers",
        "Very_Long_Answers",
        "Case_Studies",
      ];

      sections.forEach((section) => {
        if (testData[section]) {
          doc.text(section.replace(/_/g, " "), 10, y);
          y += 7;

          testData[section].forEach((questions) => {
            Object.entries(questions[0]).forEach(([questionNo, question]) => {
              doc.text(`${questionNo}. ${question}`, 10, y);
              y += 7;

              if (y > 280) {
                doc.addPage();
                y = 10;
              }
            });
          });

          y += 10;
        }
      });

      // Answers Section
      if (testData.Answers) {
        doc.text("Answers:", 10, y);
        y += 7;

        Object.entries(testData.Answers).forEach(([key, answer]) => {
          doc.text(`${key}. ${answer}`, 10, y);
          y += 7;

          if (y > 280) {
            doc.addPage();
            y = 10;
          }
        });
      }
    }

    doc.save("Generated_Exam_Paper.pdf");
  };

  if (!testData) {
    return (
      <>
        <Header />
        <Box sx={{ p: 2, pt: 10 }}>
          <Typography>No test data available</Typography>
        </Box>
      </>
    );
  }

  return (
    <>
      <Header />
      <Box sx={{ p: 4, pt: 8, fontFamily: "Arial, sans-serif" }}>
        <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
          Generated Exam Paper
        </Typography>

        <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
          General Instructions
        </Typography>
        <Box sx={{ mb: 3 }}>
          {testData.General_Instructions.map((instruction, index) => (
            <Typography key={index} variant="body1" sx={{ ml: 2 }}>
              {index + 1}. {instruction}
            </Typography>
          ))}
        </Box>

        <Divider sx={{ mb: 2 }} />

        {/* MCQs with vertical options */}
        {testData.MCQs && (
          <Box sx={{ mb: 3 }}>
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
              MCQs
            </Typography>
            {testData.MCQs.map((questions) =>
              Object.entries(questions[0]).map(([questionNo, question]) => {
                const { questionText, options } = formatMCQOptions(question);
                return (
                  <Box key={questionNo} sx={{ ml: 2, mb: 2 }}>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                      {questionNo}. {questionText}
                    </Typography>
                    {options.map((option, index) => (
                      <Typography
                        key={index}
                        variant="body1"
                        sx={{ ml: 3, mb: 0.5 }}
                      >
                        {option}
                      </Typography>
                    ))}
                  </Box>
                );
              })
            )}
          </Box>
        )}

        {/* Other sections */}
        {[
          {
            title: "True/False Questions",
            data: testData.True_False_Questions,
          },
          { title: "Fill in the Blanks", data: testData.Fill_in_the_Blanks },
          { title: "Very Short Answers", data: testData.Very_Short_Answers },
          { title: "Short Answers", data: testData.Short_Answers },
          { title: "Long Answers", data: testData.Long_Answers },
          { title: "Very Long Answers", data: testData.Very_Long_Answers },
          { title: "Case Studies", data: testData.Case_Studies },
        ].map(
          (section) =>
            section.data && (
              <Box key={section.title} sx={{ mb: 3 }}>
                <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                  {section.title}
                </Typography>
                {section.data.map((questions) =>
                  Object.entries(questions[0]).map(([questionNo, question]) => (
                    <Typography
                      key={questionNo}
                      variant="body1"
                      sx={{ ml: 2, mb: 1 }}
                    >
                      {questionNo}. {question}
                    </Typography>
                  ))
                )}
              </Box>
            )
        )}

        <Divider sx={{ mb: 2 }} />

        {/* Answers Section */}
        {testData.Answers && (
          <Box sx={{ mb: 3 }}>
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
              Answers
            </Typography>
            {Object.entries(testData.Answers).map(([key, answer]) => (
              <Typography key={key} variant="body1" sx={{ ml: 2, mb: 1 }}>
                {key}. {answer}
              </Typography>
            ))}
          </Box>
        )}

        <Box sx={{ mt: 4, display: "flex", gap: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDownloadPDF}
          >
            Download as PDF
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => navigate("/")}
          >
            Go to Home
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default TestPlanGeneratorV2;