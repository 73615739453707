import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import Header from "../components/Header"; // Adjust the import path as per your project structure
import html2pdf from "html2pdf.js"; // Import html2pdf
import Logo from "../assets/Logo.png"; // Adjust the path to your logo

const LessonPlanGeneratorV2 = () => {
  const location = useLocation();
  const navigate = useNavigate(); // Use useNavigate instead of useHistory
  const contentToExportRef = useRef(); // Reference to the HTML content for exporting as PDF
  const [lessonPlan, setLessonPlan] = useState(""); // State to store the current lesson plan

  useEffect(() => {
    // Set the lesson plan when it changes from the location state
    if (location.state?.lessonPlan) {
      setLessonPlan(location.state.lessonPlan);
    }
  }, [location.state?.lessonPlan]); // Only re-run this effect when the lessonPlan changes

  // Function to navigate to the home page
  const goToHome = () => {
    navigate("/"); // Use navigate('/') to go to the home page
  };

  // Function to export the lesson plan as PDF using html2pdf
  const exportAsPDF = () => {
    const element = contentToExportRef.current; // Get the content from ref

    // Dynamically add the logo to the top of the content
    const logoElement = document.createElement("img");
    logoElement.src = Logo;
    logoElement.alt = "Logo";
    logoElement.style.width = "150px";
    logoElement.style.marginBottom = "20px";

    // Insert the logo at the top of the content
    element.prepend(logoElement);

    // Wait for the image to load before triggering PDF generation
    logoElement.onload = () => {
      const options = {
        margin: 0.5,
        filename: "lesson_plan.pdf",
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };

      // Export the content as a PDF
      html2pdf()
        .from(element)
        .set(options)
        .save()
        .then(() => {
          // Remove the logo after export
          element.removeChild(logoElement);
        });
    };
  };

  // Markdown link transformation
  const transformLink = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, (url) => `[${url}](${url})`);
  };

  // Custom renderer for links to open them in a new tab
  const renderers = {
    a: ({ href, children }) => (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    ),
  };

  return (
    <div style={{ backgroundColor: "#FEFBF4", minHeight: "100vh" }}>
      <Header /> {/* Include your Header component */}
      <div style={{ padding: "64px" }}>
        <div ref={contentToExportRef}>
          {lessonPlan ? (
            <div>
              <ReactMarkdown rehypePlugins={[rehypeRaw]} components={renderers}>
                {transformLink(lessonPlan)}
              </ReactMarkdown>
              <div style={{ marginTop: "20px" }}>
                {/* Go to Home button */}
                <button onClick={goToHome} style={buttonStyle}>
                  Go to Home
                </button>
                {/* Export as PDF button */}
                <button onClick={exportAsPDF} style={buttonStyle}>
                  Export as PDF
                </button>
              </div>
            </div>
          ) : (
            <p>No lesson plan generated yet.</p>
          )}
        </div>
      </div>
    </div>
  );
};

// Styling for buttons
const buttonStyle = {
  backgroundColor: "#007BFF",
  color: "#fff",
  border: "none",
  padding: "10px 20px", // Consistent padding
  marginRight: "10px",
  cursor: "pointer",
  borderRadius: "4px",
  display: "inline-block",
  textAlign: "center",
  fontSize: "16px",
  height: "40px", // Ensures uniform height
  lineHeight: "20px", // Aligns text vertically
};

export default LessonPlanGeneratorV2;
