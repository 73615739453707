import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  Button,
  Grid,
  FormControl,
  InputLabel,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../components/LoadingScreen";
import UploadIcon from "@mui/icons-material/Upload"; // Material-UI upload icon


const Modal6 = ({ open, onClose }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [board, setBoard] = useState("");
  const [grade, setGrade] = useState("");
  const [subject, setSubject] = useState("");
  const [testDuration, setTestDuration] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [totalMarks, setTotalMarks] = useState("");
  const [topics, setTopics] = useState([]);
  const [questionMarks, setQuestionMarks] = useState({
    marks_of_each_mcqs: 1,
    marks_of_each_very_short_answers: 1,
    marks_of_each_short_answer: 2,
    marks_of_each_long_answer: 3,
    marks_of_each_very_long_answer: 4,
    marks_of_each_case_studies: 5,
    marks_of_each_truth_False_questions: 1,
    marks_of_each_fill_in_the_blanks: 1,
  });
  const [boards, setBoards] = useState([]);
  const [grades, setGrades] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [selectedChapters, setSelectedChapters] = useState([]); // Array for chapters added by user
  const [availableChapters, setAvailableChapters] = useState([]); // Array for chapters from API

  useEffect(() => {
    const fetchBoards = async () => {
      try {
        const response = await axios.get(
          "https://agent-utilities-api.leoqui.ai/api/pdf/Get-dropdown-values"
        );
        if (response.data.status) {
          setBoards(response.data.data.data || []);
        }
      } catch (error) {
        console.error("Error fetching boards:", error);
      }
    };

    fetchBoards();
  }, []);

  useEffect(() => {
    if (board) {
      const fetchGrades = async () => {
        try {
          const response = await axios.get(
            `https://agent-utilities-api.leoqui.ai/api/pdf/Get-dropdown-values?board=${board}`
          );
          if (response.data.status) {
            setGrades(response.data.data.data || []);
          }
        } catch (error) {
          console.error("Error fetching grades:", error);
        }
      };

      fetchGrades();
    }
  }, [board]);

  useEffect(() => {
    if (board && grade) {
      const fetchSubjects = async () => {
        try {
          const response = await axios.get(
            `https://agent-utilities-api.leoqui.ai/api/pdf/Get-dropdown-values?board=${board}&grade=${grade}`
          );
          if (response.data.status) {
            setSubjects(response.data.data.data || []);
          }
        } catch (error) {
          console.error("Error fetching subjects:", error);
        }
      };

      fetchSubjects();
    }
  }, [board, grade]);

  // Fetch chapters from API
  // Update the fetchChapters function
  useEffect(() => {
    const fetchChapters = async () => {
      if (board && grade && subject) {
        try {
          const response = await axios.get(
            `https://agent-utilities-api.leoqui.ai/api/pdf/Get-dropdown-values?board=${board}&grade=${grade}&subject=${subject}`
          );
          if (response.data.status && response.data.data?.data) {
            const chaptersData = response.data.data.data.map(
              ([chapterNumber, chapterName]) => ({
                chapter_number: chapterNumber,
                chapter_name: chapterName,
              })
            );
            setAvailableChapters(chaptersData);
          }
        } catch (error) {
          console.error("Error fetching chapters:", error);
        }
      }
    };

    fetchChapters();
  }, [board, grade, subject]);

  // Function to add empty chapter
  const addChapter = () => {
    const newChapter = {
      chapter_number: "",
      chapter_name: "",
      assigned_marks: 0,
      questions: {
        mcqs: 0,
        very_short_answers: 0,
        short_answers: 0,
        long_answers: 0,
        very_long_answers: 0,
        case_studies: 0,
        truth_False_questions: 0,
        fill_in_the_blanks: 0,
      },
    };
    setSelectedChapters([...selectedChapters, newChapter]);
  };
  // Replace the chapters section in the JSX with this updated version
  const renderChaptersSection = () => (
    <>
      {selectedChapters.length > 0 && (
        <Grid item xs={12}>
          <Typography variant="h6" mb={2}>
            Chapters
          </Typography>
          {selectedChapters.map((chapter, index) => (
            <Box key={index} mb={2}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Select Chapter</InputLabel>
                <Select
                  value={chapter.chapter_number}
                  label="Select Chapter"
                  onChange={(e) => {
                    const selectedChapter = availableChapters.find(
                      (ch) => ch.chapter_number === e.target.value
                    );
                    const newChapters = [...selectedChapters];
                    newChapters[index] = {
                      ...newChapters[index],
                      chapter_number: selectedChapter.chapter_number,
                      chapter_name: selectedChapter.chapter_name,
                    };
                    setSelectedChapters(newChapters);
                  }}
                >
                  {availableChapters.map((chapterOption) => (
                    <MenuItem
                      key={chapterOption.chapter_number}
                      value={chapterOption.chapter_number}
                    >
                      {`Chapter ${chapterOption.chapter_number}: ${chapterOption.chapter_name}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                label="Assigned Marks"
                fullWidth
                type="number"
                value={chapter.assigned_marks}
                onChange={(e) => {
                  const value = parseInt(e.target.value, 10);

                  if (value >= 0 || e.target.value === "") {
                    const newChapters = [...selectedChapters];
                    newChapters[index].assigned_marks =
                      e.target.value === "" ? "" : value;
                    setSelectedChapters(newChapters);
                  }
                }}
                sx={{ mb: 2 }}
              />

              <Typography variant="subtitle1" mb={1}>
                Question Types
              </Typography>
              <Box sx={{ mb: 2 }}>
                {Object.keys(chapter.questions).map((questionType) => (
                  <Grid container spacing={2} key={questionType} sx={{ mb: 1 }}>
                    <Grid item xs={6}>
                      <TextField
                        label={questionType.replace(/_/g, " ").toUpperCase()}
                        fullWidth
                        type="number"
                        value={chapter.questions[questionType]}
                        onChange={(e) => {
                          const value = parseInt(e.target.value, 10);

                          if (value >= 0 || e.target.value === "") {
                            const newChapters = [...selectedChapters];
                            newChapters[index].questions[questionType] =
                              e.target.value === "" ? "" : value;
                            setSelectedChapters(newChapters);
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                ))}
              </Box>

              <Button
                variant="outlined"
                color="error"
                onClick={() => {
                  const newChapters = selectedChapters.filter(
                    (_, i) => i !== index
                  );
                  setSelectedChapters(newChapters);
                }}
                sx={{ mt: 1 }}
              >
                Remove Chapter
              </Button>
            </Box>
          ))}
        </Grid>
      )}
    </>
  );
  const addTopic = () => {
    setTopics([
      ...topics,
      {
        topic_name: "",
        assigned_marks: 0,
        questions: {
          mcqs: 0,
          very_short_answers: 0,
          short_answers: 0,
          long_answers: 0,
          very_long_answers: 0,
          case_studies: 0,
          truth_False_questions: 0,
          fill_in_the_blanks: 0,
        },
        file: null,
      },
    ]);
  };

  // Update the handleShowTestStructure function
  const handleShowTestStructure = () => {
    console.log("Test Structure:", {
      board,
      grade,
      subject,
      testDuration,
      difficulty,
      totalMarks,
      chapters: selectedChapters,
      topics,
      questionMarks,
    });
  };

  const handleSubmit = async () => {
    const testData = {
      board: board || "N/A",
      grade: grade || "N/A",
      subject: subject || "N/A",
      test_duration: testDuration || 0,
      difficulty: difficulty || "N/A",
      total_marks: totalMarks || 0,
      topics: topics.map((topic) => ({
        topic_name: topic.topic_name || "N/A",
        assigned_marks: Number(topic.assigned_marks) || 0,
        questions: [
          {
            mcqs: Number(topic.questions.mcqs) || 0,
            very_short_answers: Number(topic.questions.very_short_answers) || 0,
            short_answers: Number(topic.questions.short_answers) || 0,
            long_answers: Number(topic.questions.long_answers) || 0,
            very_long_answers: Number(topic.questions.very_long_answers) || 0,
            case_studies: Number(topic.questions.case_studies) || 0,
            truth_False_questions:
              Number(topic.questions.truth_False_questions) || 0,
            fill_in_the_blanks: Number(topic.questions.fill_in_the_blanks) || 0,
          },
        ],
      })),
      chapters: selectedChapters.map((chapter) => ({
        chapter_number: chapter.chapter_number || "N/A",
        assigned_marks: Number(chapter.assigned_marks) || 0,
        questions: [
          {
            mcqs: Number(chapter.questions.mcqs) || 0,
            very_short_answers:
              Number(chapter.questions.very_short_answers) || 0,
            short_answers: Number(chapter.questions.short_answers) || 0,
            long_answers: Number(chapter.questions.long_answers) || 0,
            very_long_answers: Number(chapter.questions.very_long_answers) || 0,
            case_studies: Number(chapter.questions.case_studies) || 0,
            truth_False_questions:
              Number(chapter.questions.truth_False_questions) || 0,
            fill_in_the_blanks:
              Number(chapter.questions.fill_in_the_blanks) || 0,
          },
        ],
      })),
      marks_of_each_questions: {
        ...questionMarks,
        // Ensure all values are numbers
        marks_of_each_mcqs: Number(questionMarks.marks_of_each_mcqs),
        marks_of_each_very_short_answers: Number(
          questionMarks.marks_of_each_very_short_answers
        ),
        marks_of_each_short_answer: Number(
          questionMarks.marks_of_each_short_answer
        ),
        marks_of_each_long_answer: Number(
          questionMarks.marks_of_each_long_answer
        ),
        marks_of_each_very_long_answer: Number(
          questionMarks.marks_of_each_very_long_answer
        ),
        marks_of_each_case_studies: Number(
          questionMarks.marks_of_each_case_studies
        ),
        marks_of_each_truth_False_questions: Number(
          questionMarks.marks_of_each_truth_False_questions
        ),
        marks_of_each_fill_in_the_blanks: Number(
          questionMarks.marks_of_each_fill_in_the_blanks
        ),
      },
    };

    // Create FormData
    const formData = new FormData();
    formData.append("data", JSON.stringify(testData));

    // Append files from topics
    topics.forEach((topic, i) => {
      if (topic.file) {
        formData.append(`files`, topic.file, topic.file.name || `file_${i}`);
      }
    });
    try {
      setLoading(true);

      // First, check internet connection
      if (!navigator.onLine) {
        throw new Error(
          "No internet connection. Please check your connection and try again."
        );
      }

      const response = await axios.post(
        "https://tpg-api.leoqui.ai/testpaper/test_paper_generator_topics_and_cbse_both",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
          // Add timeout
          timeout: 300000, // 30 seconds
          withCredentials: false,
          retry: 3,
          retryDelay: 1000,
        }
      );

      if (response.data.status === "success") {
        navigate("/test-plan-generator", {
          state: { TestPlanGeneratorV2: response.data },
        });
      } else {
        // Throw an error with the message from the API response
        throw new Error(
          response.data.message ||
            "Test plan could not be generated. Please try again."
        );
      }
    } catch (error) {
      console.error("Error submitting the form:", error);

      let errorMessage;

      if (!navigator.onLine) {
        errorMessage =
          "No internet connection. Please check your connection and try again.";
      } else if (error.code === "ECONNABORTED") {
        errorMessage = "Request timed out. Please try again.";
      } else if (error.response) {
        // Server responded with an error
        errorMessage =
          error.response.data?.message || "Server error. Please try again.";
      } else if (error.request) {
        // Request made but no response
        errorMessage = "No response from server. Please try again.";
      } else {
        // Fallback for other errors
        errorMessage =
          error.message ||
          "An error occurred while generating the lesson plan.";
      }

      // Display the specific error message
      alert(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <LoadingScreen />}
      <Modal
        open={open}
        onClose={onClose}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflowY: "scroll",
        }}
      >
        <Box
          sx={{
            width: 600,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            maxHeight: "90vh",
            overflowY: "auto",
          }}
        >
          <Typography variant="h5" component="h2" mb={3}>
            Exam Paper Generator V2
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel>Board</InputLabel>
                <Select
                  value={board}
                  label="Board"
                  onChange={(e) => setBoard(e.target.value)}
                >
                  {boards.map((board, index) => (
                    <MenuItem key={index} value={board}>
                      {board}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel>Grade</InputLabel>
                <Select
                  value={grade}
                  label="Grade"
                  onChange={(e) => setGrade(e.target.value)}
                >
                  {grades.map((grade, index) => (
                    <MenuItem key={index} value={grade}>
                      {grade}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel>Subject</InputLabel>
                <Select
                  value={subject}
                  label="Subject"
                  onChange={(e) => setSubject(e.target.value)}
                >
                  {subjects.map((subject, index) => (
                    <MenuItem key={index} value={subject}>
                      {subject}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Test Duration"
                type="number"
                value={testDuration}
                onChange={(e) => {
                  const value = parseInt(e.target.value, 10);

                  if (value >= 0 || e.target.value === "") {
                    setTestDuration(e.target.value === "" ? "" : value);
                  }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel>Difficulty</InputLabel>
                <Select
                  value={difficulty}
                  label="Difficulty"
                  onChange={(e) => setDifficulty(e.target.value)}
                >
                  <MenuItem value="Easy">Easy</MenuItem>
                  <MenuItem value="Medium">Medium</MenuItem>
                  <MenuItem value="Hard">Hard</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Total Marks"
                type="number"
                value={totalMarks}
                onChange={(e) => {
                  const value = parseInt(e.target.value, 10);

                  if (value >= 0 || e.target.value === "") {
                    setTotalMarks(e.target.value === "" ? "" : value);
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} container spacing={2}>
              <Grid item>
                <Button variant="outlined" onClick={addChapter}>
                  + Chapter
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={addTopic}>
                  + Topic
                </Button>
              </Grid>
            </Grid>
            {/* Chapters Section */}
            {renderChaptersSection()}
            {/* Topics Section */}
            {topics.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="h6" mb={2}>
                  Topics
                </Typography>
                {topics.map((topic, index) => (
                  <Box key={index} mb={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => {
                            const newTopics = topics.filter(
                              (_, idx) => idx !== index
                            );
                            setTopics(newTopics);
                          }}
                        >
                          Remove Topic
                        </Button>
                      </Grid>
                    </Grid>
                    {/* Topic Name */}
                    <TextField
                      label="Topic Name"
                      fullWidth
                      value={topic.topic_name}
                      onChange={(e) => {
                        const newTopics = [...topics];
                        newTopics[index].topic_name = e.target.value;
                        setTopics(newTopics);
                      }}
                      sx={{ mb: 2 }}
                    />
                    {/* Assigned Marks */}
                    <TextField
                      label="Assigned Marks"
                      fullWidth
                      type="number"
                      value={topic.assigned_marks}
                      onChange={(e) => {
                        const value = parseInt(e.target.value, 10);

                        if (value >= 0 || e.target.value === "") {
                          const newTopics = [...topics];
                          newTopics[index].assigned_marks =
                            e.target.value === "" ? "" : value;
                          setTopics(newTopics);
                        }
                      }}
                      sx={{ mb: 2 }}
                    />
                    {/* Question Types */}
                    <Typography variant="subtitle1" mb={1}>
                      Question Types
                    </Typography>
                    <Box sx={{ mb: 2 }}>
                      {[
                        "mcqs",
                        "very_short_answers",
                        "short_answers",
                        "long_answers",
                        "very_long_answers",
                        "case_studies",
                        "truth_False_questions",
                        "fill_in_the_blanks",
                      ].map((questionType) => (
                        <Grid
                          container
                          spacing={2}
                          key={questionType}
                          sx={{ mb: 1 }}
                        >
                          <Grid item xs={6}>
                            <TextField
                              label={questionType
                                .replace(/_/g, " ")
                                .toUpperCase()}
                              fullWidth
                              type="number"
                              value={topic.questions[questionType] || 0}
                              onChange={(e) => {
                                const value = parseInt(e.target.value, 10);

                                if (value >= 0 || e.target.value === "") {
                                  const newTopics = [...topics];
                                  newTopics[index].questions[questionType] =
                                    e.target.value === "" ? "" : value;
                                  setTopics(newTopics);
                                }
                              }}
                            />
                          </Grid>
                        </Grid>
                      ))}
                    </Box>

                    {/* File Upload */}
                    <Box sx={{ mb: 2 }}>
                      <input
                        type="file"
                        id={`file-upload-${index}`} // Unique ID for each input
                        style={{ display: "none" }} // Hide the default file input
                        onChange={(e) => {
                          const newTopics = [...topics];
                          newTopics[index].file = e.target.files[0]; // Save file in the corresponding topic
                          setTopics(newTopics); // Update state
                        }}
                      />
                      <Typography variant="subtitle1" mb={1}>
                        Upload Reference File:
                      </Typography>
                      <label htmlFor={`file-upload-${index}`}>
                        <Button
                          variant="outlined"
                          component="span"
                          fullWidth
                          sx={{
                            textTransform: "none",
                            borderRadius: 2,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 1,
                            padding: 2,
                          }}
                        >
                          <UploadIcon /> {/* Add a file upload icon */}
                          Upload File
                        </Button>
                      </label>
                      {topics[index].file && (
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ mt: 1, textAlign: "center" }}
                        >
                          Selected File: {topics[index].file.name}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                ))}
              </Grid>
            )}
            {/* Marks of Each Question Section */}
            <Grid item xs={12}>
              <Typography variant="h6" mb={2}>
                Marks of Each Question
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Marks of MCQ"
                    type="number"
                    fullWidth
                    value={questionMarks.marks_of_each_mcqs}
                    onChange={(e) => {
                      const value = parseInt(e.target.value, 10);
                      if (value >= 0 || e.target.value === "") {
                        setQuestionMarks({
                          ...questionMarks,
                          marks_of_each_mcqs:
                            e.target.value === "" ? "" : value,
                        });
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Marks of Very Short Answer"
                    type="number"
                    fullWidth
                    value={questionMarks.marks_of_each_very_short_answers}
                    onChange={(e) => {
                      const value = parseInt(e.target.value, 10);
                      if (value >= 0 || e.target.value === "") {
                        setQuestionMarks({
                          ...questionMarks,
                          marks_of_each_very_short_answers:
                            e.target.value === "" ? "" : value,
                        });
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Marks of Short Answer"
                    type="number"
                    fullWidth
                    value={questionMarks.marks_of_each_short_answer}
                    onChange={(e) => {
                      const value = parseInt(e.target.value, 10);
                      if (value >= 0 || e.target.value === "") {
                        setQuestionMarks({
                          ...questionMarks,
                          marks_of_each_short_answer:
                            e.target.value === "" ? "" : value,
                        });
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Marks of Long Answer"
                    type="number"
                    fullWidth
                    value={questionMarks.marks_of_each_long_answer}
                    onChange={(e) => {
                      const value = parseInt(e.target.value, 10);
                      if (value >= 0 || e.target.value === "") {
                        setQuestionMarks({
                          ...questionMarks,
                          marks_of_each_long_answer:
                            e.target.value === "" ? "" : value,
                        });
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Marks of Very Long Answer"
                    type="number"
                    fullWidth
                    value={questionMarks.marks_of_each_very_long_answer}
                    onChange={(e) => {
                      const value = parseInt(e.target.value, 10);
                      if (value >= 0 || e.target.value === "") {
                        setQuestionMarks({
                          ...questionMarks,
                          marks_of_each_very_long_answer:
                            e.target.value === "" ? "" : value,
                        });
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Marks of Case Studies"
                    type="number"
                    fullWidth
                    value={questionMarks.marks_of_each_case_studies}
                    onChange={(e) => {
                      const value = parseInt(e.target.value, 10);
                      if (value >= 0 || e.target.value === "") {
                        setQuestionMarks({
                          ...questionMarks,
                          marks_of_each_case_studies:
                            e.target.value === "" ? "" : value,
                        });
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Marks of True/False Question"
                    type="number"
                    fullWidth
                    value={questionMarks.marks_of_each_truth_False_questions}
                    onChange={(e) => {
                      const value = parseInt(e.target.value, 10);
                      if (value >= 0 || e.target.value === "") {
                        setQuestionMarks({
                          ...questionMarks,
                          marks_of_each_truth_False_questions:
                            e.target.value === "" ? "" : value,
                        });
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Marks of Fill in the Blanks"
                    type="number"
                    fullWidth
                    value={questionMarks.marks_of_each_fill_in_the_blanks}
                    onChange={(e) => {
                      const value = parseInt(e.target.value, 10);
                      if (value >= 0 || e.target.value === "") {
                        setQuestionMarks({
                          ...questionMarks,
                          marks_of_each_fill_in_the_blanks:
                            e.target.value === "" ? "" : value,
                        });
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={2}>
              <Grid item>
                <Button variant="contained" onClick={handleShowTestStructure}>
                  Show Test Structure
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default Modal6;
